<template>
  <div>
    <el-form label-position="top">
      <el-row>
        <el-col :span="20" :offset="2">
          <el-row :gutter="20" type="flex">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <header>Бүтээгдэхүүн: <strong>{{data.product_name_mon}}</strong><el-tag :type="data.is_accepted === 'cancel' ? 'danger' : data.is_accepted === 'pending' ? '' : 'success'">{{data.is_accepted}}</el-tag></header>
                  <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col>
                      <div class="panel">
                      <el-row type="flex">
                        <el-col>
                          <div class="panel-item">
                            <header>Зураг</header>
                            <img :src="data.variant_image" alt="" style="width: 80px">
                          </div>
                        </el-col>
                        <el-col>
                        <div class="panel-item">
                          <header>Төрөл</header>
                          <div class="panel-content">
                            {{data.variant_name}}
                          </div>
                        </div>
                        </el-col>
                      </el-row>
                      </div>
                      <div>
                        <div class="panel-item">
                          <header>Үнэ</header>
                          <el-row :gutter="20" type="flex">
                            <el-col :span="12">
                              <el-form-item label="Татах үнэ">
                                <el-input v-model="data.get_price" type="number" placeholder="0₮">
                                  <template slot="append">₮</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="НӨАТ-гүй татах үнэ">
                                <el-input v-model="data.get_price_without_tax" disabled type="number" placeholder="0₮">
                                  <template slot="append">₮</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="20" type="flex">
                            <el-col :span="12">
                              <el-form-item label="Зарах үнэ">
                                <el-input v-model="data.sell_price" type="number" placeholder="0₮">
                                  <template slot="append">₮</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="НӨАТ-гүй зарах үнэ">
                                <el-input v-model="data.sell_price_without_tax" disabled type="number" placeholder="0₮">
                                  <template slot="append">₮</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="20" type="flex">
                            <el-col :span="12">
                              <el-form-item label="Хямдралтай үнэ">
                                <el-input v-model="data.discounted_price" :disabled="data.is_discount === false ? true : false" type="number" placeholder="0₮">
                                  <template slot="append">₮</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="Хямдралтай эсэх">
                                <el-checkbox v-model="data.is_discount"></el-checkbox>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="20" type="flex">
                            <el-col :span="12">
                              <el-form-item label="НӨАТ-гүй бохир ашиг">
                                <el-input v-model="data.profit_without_tax" disabled type="number" placeholder="0₮">
                                  <template slot="append">₮</template>
                                </el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item label="НӨАТ-гүй бохир ашиг маржин">
                                  <template>{{data.profit_percent_without_tax}}%</template>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
            <div class="panel">
                    <div class="panel-item">
                      <header>Нийлүүлэгч байгууллага</header>
                      <div>
                        <div>
                          <strong class="uppercase">{{
                            data.supplier.supplier_monName
                          }}</strong>
                        </div>
                        <p>
                        </p>
                        <p>
                          {{data.supplier.address}}
                        </p>
                        <p>{{ data.supplier.phone }}</p>
                        <p>info@goyo.mn</p>
                      </div>
                    </div>
                  </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="danger" @click="deletePrice" style="float:left">Устгах</el-button>
            <el-button type="default" @click="closeOrderdetail">Буцах</el-button>
            <el-button type="success" @click="updatePrice">Шинэчлэх</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      data: null
    }
  },
  created () {
    this.data = this.$route.params.data
  },
  methods: {
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    closeOrderdetail () {
      this.$router.go(-1)
    },
    deletePrice () {
      services.deletePrice(this.data.id).then(el => {
        if (el.status === 'success') {
          this.alertReporter('Амжилттай', el.message, 'success')
        } else {
          this.alertReporter('Алдаа', el.message, 'error')
        }
      })
    },
    updatePrice () {
      const body = {
        supplier_id: this.data.supplier_id,
        product_id: this.data.product_id,
        variant_id: this.data.variant_id,
        get_price: this.data.get_price,
        sell_price: this.data.sell_price,
        discounted_price: this.data.is_discount === true ? this.data.discounted_price : '0',
        is_discount: this.data.is_discount
      }
      services.putUpdatePrice(this.data.id, body).then(el => {
        if (el.status === 'success') {
          this.alertReporter('Амжилттай', el.message, 'success')
        } else {
          this.alertReporter('Алдаа', el.message, 'error')
        }
      })
    }
  }
}
</script>

<style>

</style>
